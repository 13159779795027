import React from 'react';
import HeaderOne from "../common/header/HeaderOne";
import SlipFour from '../elements/split2/SlipFour';
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from "../common/footer/ScrollTop";





const Home = () => {
    return (
        <>
            <HeaderOne />
            <SlipFour />


            <CopyrightTwo />
            <ScrollTop />



        </>
    )
}

export default Home;