// Import Router-dom
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Import Css Here 
import './assets/scss/style.scss';
// Import Pages Here
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import GalleryPhoto from './pages/GalleryPhoto';
import Trailer from './pages/Trailer';
import Interviewdirector from './pages/Interviewdirector';
import Interviewactor from './pages/Interviewactor';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/trailer' element={<Trailer />} />
                <Route path='/interviewdirector' element={<Interviewdirector />} />
                <Route path='/interviewactor' element={<Interviewactor />} />
                <Route path='/galleryphoto' element={<GalleryPhoto />} />
                <Route path='/contactpage' element={<ContactPage />} />
            </Routes>
        </BrowserRouter>





    )
}
export default App;
