import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";



const SlipTwo = () => {
    return (
        <div className="rn-splite-style">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/split/alexandre-desane.jpg" alt="split Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h4 className="title">Biography</h4>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">Alexandre Desane is a French actor, photographer and director of Haitian background.</p>
                                <p className="description">After playing in several theaters and taking part in film projects, in 2020, Alexandre co-directed a documentary series on self-taught people in France Dans la jungle, avec un petit couteau à beurre…(In the Jungle with a Small Butter Knife)...supported by the CNC-Talent. Alexandre has also directed his first short film L’enfant Orange which depicts the vision of racism from a black child perspective. The film has been selected in several festivals and was shown in schools.</p>
                                <p className="description">In 2021, Alexandre played one of the supporting roles in the feature film The Braves, presented at the Directors' Fortnight in Cannes.</p>
                                <p className="description">As a photographer, one of his photos was selected by Martin Parr to be exhibited in large format in 13 Metro stations in Paris, back in 2018 </p>
                                <p className="description">In 2022, Alexandre published his first photo book Crépus, a series that celebrates frizzy hair. </p>
                                <p className="description">Alexandre plays the lead role of the revolutionary psychiatrist Frantz Fanon in a feature film directed by Abdenour Zahzah selected for the Berlinale Forum 2024.</p>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h4 className="title">Filmography</h4>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">2024 - The True Chronicles of the Blida-Joinville Psychiatric Hospital in the Last Century, when Dr Frantz Fanon was Head of… the Fifth Ward between 1953 and 1956. ABDENOUR ZAHZAH.</p>
                                <h6>2022<p className='description'>The Braves. ANAÏS VOLPÉ</p> </h6>
                                <h6>2016<p className='description'>HEIS (chroniques). ANAÏS VOLPÉ.</p> </h6>
                                <h6>2014<p className='description'>RUN. PHILIPPE LACÔTE.</p> </h6>

                            </ScrollAnimation>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipTwo;