import React from 'react';
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";


const PopupData = [
    {
        id: "01",
        image: "./images/portfolio/PhotoFanon01.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon01.jpg',

        ],
    },
    {
        id: "02",
        image: "./images/portfolio/PhotoFanon06.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon06.jpg',
        ],
    },

    {
        id: "04",
        image: "./images/portfolio/PhotoFanon03.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon03.jpg',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/PhotoFanon04.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon04.jpg',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/PhotoFanon05.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon05.jpg',
        ],
    },


    {
        id: "09",
        image: "./images/portfolio/PhotoFanon02.jpg",
        popupLink: [
            './images/portfolio/PhotoFanon02.jpg',
        ],
    },



]



const Elements = () => {
    return (
        <>



            <div className="main-content">


                {/* Start Elements Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle=""
                                    title=""
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {PopupData.map((item) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}





            </div>

        </>
    )
}
export default Elements;