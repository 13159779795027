import React from 'react';
import Download from "../download/Download";
import ScrollAnimation from "react-animate-on-scroll";



const SlipFour = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inn">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h3 className="title">The first feature film about the author of<br />«Les Damnés de la Terre»<br />(The Wretched of the Earth) </h3>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h6><br /> SYNOPSIS</h6>
                                <p className="description">1953, colonized Algeria <br />Fanon, a young black psychiatrist is appointed head doctor at the Blida-Joinville Hospital. He was putting his theories of 'Institutional Psychotherapy' into practice in opposition to the racist theories of the Algies School of Psychiatry, while a war broke out in his own wards.</p>
                            </ScrollAnimation>
                            <Download />

                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/split/Fanon-Press-Release.jpg" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default SlipFour;