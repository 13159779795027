import React from 'react';
import { Player, BigPlayButton } from 'video-react';

const VideoApp = () => {
    return (
        <Player
            playsInline
            poster="./images/logo/FrantzFanon.jpg"
            src="./images/video/SequenceFinal.mp4"

        >
            <BigPlayButton position="center" />
        </Player>
    );
};

export default VideoApp;