import React from 'react';
import Gallery from "../elements/gallery/Gallery";
import HeaderOne from "../common/header/HeaderOne";
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from '../common/footer/ScrollTop';


const GalleryPhoto = () => {
    return (
        <>
            <HeaderOne />
            <Gallery />
            <CopyrightTwo />
            <ScrollTop />
        </>
    )
}

export default GalleryPhoto;