import FanonPressBookpdf from "./FanonPressBook.pdf";



const Download = () => {
    return (
        <>
            <a
                href={FanonPressBookpdf}
                download="FanonPressBook.pdf"
                target="_blank"
                rel="noreferrer"
            >
                <div className="form-group">
                    <button className="btn-default btn-large">Press book</button>
                </div>

            </a>
        </>
    )
}

export default Download;