import React from 'react';
import { FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <ul className="social-icon social-default color-lessdark justify-content-center">


                        <li><Link to="https://www.instagram.com/frantz.fanon.the.movie/?igsh=ZW5oZXBrbjVkYXBv"><FiInstagram /></Link></li>

                    </ul>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right start text-lg-right">
                            <p className="copyright-text">Copyright All rights reserved © {new Date().getFullYear()}<br />ATLAS FILM PRODUCTION ALGERIA</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default CopyrightTwo;