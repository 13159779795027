import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import VideoApp from "../elements/video/VideoApp";


const Trailer = () => {
    return (
        <>
            <HeaderOne />
            <div className="rwt-contact-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--40">

                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle=""
                                title=""
                                description=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <VideoApp />
        </>
    )
}
export default Trailer;