import React from 'react';
import Contact1 from "../elements/contact/Contact1";
import HeaderOne from "../common/header/HeaderOne";
import CopyrightTwo from "../common/footer/CopyrightTwo";
import ScrollTop from "../common/footer/ScrollTop";



const ContactPage = () => {
    return (
        <>

            <HeaderOne />
            <Contact1 />
            <CopyrightTwo />
            <ScrollTop />
        </>

    )
}

export default ContactPage;