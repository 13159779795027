
import React from 'react'
import { Link } from "react-router-dom";

const Nav = () => {

    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>
            <li><Link to='/trailer'>Trailer</Link></li>
            <li><Link to='/interviewdirector'>Director Interview</Link></li>
            <li><Link to='/interviewactor'>Lead Actor</Link></li>
            <li><Link to='/galleryphoto'>Gallery</Link></li>
            <li className="has-droupdown"><Link to='/contactpage'>Contact</Link></li>
        </ul>
    )
}
export default Nav;
