import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SlpitOne = () => {
    return (
        <div className="rn-splite-style">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail image-left-content">
                            <img src="./images/split/test.jpg" alt="split Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h6 className="title">Biography</h6>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description"> Abdenour Zahzah is an Algerian screenwriter, director and producer. After studying at
                                    the University of Algiers, he worked as a programmer at the Blida Cinémathèque between
                                    1997 and 2004. He completed his first film, Frantz Fanon, Mémoire d’Asile in 2002. After
                                    that, he traveled to France, where he made two documentary films, and spent a long time
                                    at the Moulin d’Andé in Normandy, where he made a film with the writer Maurice Pons.
                                    Returning to Algeria in 2007, he made several commissioned documentaries. But it was
                                    with his short fiction film Garagouz, a multi-award-winning film, that he made a name
                                    for himself. After a feature-length documentary, L’Oued, L’Oued, which won critical
                                    acclaim at festivals, he directed his first feature-length fiction film in 2024, about Dr.
                                    Frantz Fanon’s Blida-Joinville years.</p>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h4 className="title">Filmography</h4>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h6>2024<p className='description'>The True Chronicles of the Last Century at the Blida-Joinville Psychiatric Hospital,
                                    when Dr Frantz Fanon was Head of the Fifth Ward between 1953 and 1956. Feature.
                                    90 min.</p> </h6>
                                <h6>2023<p className='description'>Pierre Clément, Cinéma & Révolution. Doc. 70 min</p> </h6>
                                <h6>2013<p className='description'>El Oued El Oued (The River). Doc. 86 mn</p> </h6>
                                <h6>2011<p className='description'>Andalucia. Doc. 63 mn</p> </h6>
                                <h6>2010<p className='description'>Garagouz. Short. 21 mn</p> </h6>
                                <h6>2009<p className='description'>The Long Road to the NEPAD. Doc. 60 mn</p> </h6>
                                <h6>2007<p className='description'>Maurice Pons, L’écrivain de l’étrange. Doc. 67 mn</p> </h6>
                                <h6>2006<p className='description'>Le Non-Faire. Doc. 50 mn </p> </h6>
                                <h6>2005<p className='description'>Under the Sun the Lead. Doc. 52 mn</p> </h6>
                                <h6>2002<p className='description'>Frantz Fanon, Mémoire d’Asile. Doc. 54 mn</p> </h6>
                            </ScrollAnimation>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlpitOne
