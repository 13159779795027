import React from 'react';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import HeaderOne from "../common/header/HeaderOne";
import SlipTwo from "../elements/split2/SlipTwo";
import CopyrightTwo from "../common/footer/CopyrightTwo";
import ScrollTop from "../common/footer/ScrollTop";



const Interviewactor = () => {
    return (
        <>
            <HeaderOne />

            <SlipTwo />
            <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle=""
                title="Have you heard about Frantz Fanon before? What does Frantz Fanon mean to you now that you performed their character?"
                description="It was my first year of high school when I first heard about Frantz Fanon. A couple of rap artists I used to listen to in my MP3 Player mentioned Fanon, so I tried to find out more about this person quoted by rappers just like were Sankara, Césaire and Kateb Yacine. I bought and read Black Skin, White Masks, and now that I have the great opportunity to ...Franz Fanon, I am so humbled, and delighted to do it for a true humanist, a defender of the oppressed.
                This movie actually helped me digging deeper in Fanon’s The Wretched of the Earth I had the privilege of meeting two very inspiring people, his son Olivier Fanon, and one of his grandsons Cédric Fanon, who both have been on the shooting scene.
                It was also a great opportunity for me to discover Algeria, such a beautiful country!, and working with a super demanding director. This was an overwhelming and emotional journey."

            />

            <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                title="What prompted you to star in this film?"
                description="Director Damien Ounouri suggested his peer and friend, the film-director Abdenour Zahzah to offer me the role of Frantz Fanon. At the end of 2019, Abdenour contacted me while he was in Paris spotting actors. When I read the script, I was immediately hooked. Abdenour, who had been interested in Fanon for many years, wanted above all to give credit to Fanon’s work. at the hospital, and to focus on who was Fanon the psychiatrist. In addition, Abdenour introduced me to his environment, and offered me the opportunity to watch his short film Garagouz, I very much liked this film poetry, the reason why I was very eager to work with Abdenour."
            />

            <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                title="Shootings took place in actual setting, where Frantz Fanon evolved. How did it go?"
                description="The shootings took place in Blida, an immersion in the city daily life that made the work quite interesting, like a play that is performed in the open air. Abdenour made sure that the film could be shot in the hospital where Frantz Fanon was working, and which bears his name since Algeria’s  independence, as well as the official accommodation Fanon used to stay in, which currently is the Fanon Museum. It's a great opportunity for an actor to play behind walls that have a history. Sixty years later, I walked in the same hospital corridors, the same office, the same room as Frantz Fanon."
            />

            <Separator />
            <Separator />
            <CopyrightTwo />
            <ScrollTop />
        </>
    )
}

export default Interviewactor;