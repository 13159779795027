import React from 'react';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import HeaderOne from "../common/header/HeaderOne";
import SlpitOne from "../elements/split2/SlpitOne";
import CopyrightTwo from "../common/footer/CopyrightTwo";
import ScrollTop from "../common/footer/ScrollTop";

const Interviewdirector = () => {
    return (
        <>

            <HeaderOne />
            <SlpitOne />
            <div className="rwt-contact-area rn-section-gap">
                <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title=" How did the idea for this film come about?"
                    description="A documentary film about Frantz Fanon was already shot back in 2002. It was the first film I have
                    directed with Dr
                     Bachir Ridouh. Since then, I’ve been dreaming of directing Frantz Fanon in a
                    feature film. Frantz Fanon died very young at the age of 36, and lived in hiding with the Algerian
                    activists when he was 31 years old. However, very few photos and almost no film about Fanon exist.
                    My film is first and foremost a representation of a ‘living’ memory, recalling the mythical author of
                    Black Skin, White Masks. "

                />

                <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Why is the film title that long?"
                    description="The title of the film presents the aspect I chose to depict, where Frantz Fanon held the position of
                    chief physician at the HPB, a psychiatric hospital in Blida-Joinville between 1953 and 1956. Fanon
                    has often been portrayed as an anti-colonialist activist, a politician, and a black man who experienced
                    racism while he was studing in Lyon-France to become a doctor. He was also a soldier defending
                    France-libre against Nazism. However, while working as a psychiatrist, he turned to a political thinker
                    by joining the Algerian Front de Libération nationale. Fanon’s advocacy for independence was the
                    main theme of his famous letter of resignation from his post in Blida, sent to Robert Lacoste, then
                    Governor General of French Algeria:<br/> 'If psychiatry is the medical technique that aims to enable man to
                    no longer feel alienated from their environment, I must affirm that the Arab, permanently alienated in
                    their country, live in a state of absolute depersonalization.'<br/>From then on, Fanon considered that there was absolutely no point in practicing hospital psychiatry,
                    that is, to give back a sense of freedom to the patient inside the hospital if, once out of that hospital,
                    this cured patient is back to being a colonized person."
                />
                <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="What about the screenplay and the dialogues writing?"
                    description="From my documentary made between 1998 and 2002, I kept key material found in the archives of the hospital, including the diary that Frantz Fanon kept with his patients, in addition to the cases presented in chapter five of The Wretched of the Earth: Colonial War and Mental Disorders, Fanon used to write his clinical notes while working in Blida. These documents shaped the screenplay cornestone, which then had to be written with attention to the credibility and authenticity of the facts, since Fanon was well known. Therefore, there was no room for pure fiction.<br/>In addition, watching several films, mostly documentaries pertaining to psychiatry helped me designing the film setting in a psychiatric hospital, and documenting its atmosphere as it was governed during the the 50s in the last century. "
                />
                <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="By the way, the line between documentary and fiction is unsettling in this film..."
                    description="With the exception of the acting, I would say that the film is essentially documentary. First of all, by its decorations. I wanted the film to be shot in the same place where Frantz Fanon lived. From the moment I chose to tell the story of Fanon the psychiatrist, and beyond the film characters, the subject focused on the profession of a psychiatrist in a hospital environment. From then on, shooting in a psychiatric hospital turned to be key despite the expected challenges. To me, I couldn't think of anything better than the psychiatric hospital where Frantz Fanon practiced and which now bears his name. Then, for part of the casting, I chose to offer roles to be performed by non-actors to interpret, if not their own characters. Some doctors and nurses agreed, as did Father Paul Desfarge, an admirer of Fanon, who agreed to animate the Christmas Mass, refering to the one organized by Dr. Fanon for his patients in 1953. Same with Frantz's son, Olivier Fanon,  who has been so kind in sharing a scene with his character who was a baby at the time of the action. It was a delightful mise en abyme that we allowed ourselves based on an idea of Alexandre Desane."
                />
                <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="How did the rest of the cast go?"
                    description="There have been several castings and several types of castings. Several castings because the film was supposed to be shot in March 2020, before the COVID pandemic hit and caused the closure of borders for more than a year. So the first casting fell by the wayside except for the lead actor, Alexandre Desane , who remained faithful to the project. Then the castings are different because of course we needed Algerian actors and the Wodjouh Agency helped us screening them. The French actors were spotted with the help of a peer of mine based in Marseille-France, Bania Medjbar. As mentioned above, the non-actors that we chose according to the scenes and the circumstances, and finally the extras who were very involved in the project and helped expediting the shooting within the hospital."
                />
            </div>


            <Separator />
            <CopyrightTwo />
            <ScrollTop />
        </>
    )
}

export default Interviewdirector;